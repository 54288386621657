import React, { useState, ChangeEvent, FormEvent } from "react";
import { Link } from "wouter";
import { CenteredMain, BorderedDiv } from "./container";
import { navigate, Route, RouteKind, serializeRoute } from "./route";

enum ErrorType {
  UNREGISTERED_USER,
  OTHER_ERROR,
}

export function Signin() {
  const [error, setError] = useState<null | ErrorType>(null);
  const [formData, setFormData] = useState({
    email: "",
    showIsUnregistered: false,
    submitInProgress: false,
  });

  const handleTextChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (formData.submitInProgress) {
      throw new Error("Submit already in progress");
    }

    try {
      setError(null);
      setFormData((prev) => ({
        ...prev,
        submitInProgress: true,
      }));

      let signinPayload = {
        email: formData.email,
      };

      const response = await fetch("/api/signin", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(signinPayload),
      });

      if (response.status === 404 /* NOT FOUND */) {
        setError(ErrorType.UNREGISTERED_USER);
        return;
      }

      if (response.status === 202 /* ACCEPTED */) {
        navigate({ kind: RouteKind.SigninConfirm });
        return;
      }

      throw new Error(`Invalid response status code: ${response.status}`);
    } catch (err) {
      console.error("Error registering user:", err);
      setError(ErrorType.OTHER_ERROR);
      throw err;
    } finally {
      setFormData((prev) => ({
        ...prev,
        submitInProgress: false,
      }));
    }
  };

  const errorBanner = (() => {
    switch (error) {
      case null:
        return null;
      case ErrorType.UNREGISTERED_USER: {
        const signupRoute: Route = {
          kind: RouteKind.Signup,
          prefillEmail: formData.email,
        };
        return (
          <div className="mb-3 alert alert-info" role="alert">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 16,
              }}
            >
              <div>Sie sind noch nicht registriert.</div>
              <Link href={serializeRoute(signupRoute)} className="btn btn-dark">
                Registrieren
              </Link>
            </div>
          </div>
        );
      }
      case ErrorType.OTHER_ERROR:
        return (
          <div className="mb-3 alert alert-danger" role="alert">
            Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch
            einmal.
          </div>
        );
      default: {
        const exhaustive: never = error;
        throw new Error(`Unhandled error type: ${exhaustive}`);
      }
    }
  })();

  let spinner = null;
  if (formData.submitInProgress) {
    spinner = (
      <div className="mb-3 text-center">
        <div className="spinner-border" role="status"></div>
      </div>
    );
  }

  return (
    <CenteredMain>
      <BorderedDiv>
        <h1 className="mb-5 text-center">Willkommen zurück!</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="emailInput" className="form-label">
              Emailadresse
            </label>
            <input
              type="email"
              className="form-control"
              id="emailInput"
              name="email"
              value={formData.email}
              onChange={handleTextChange}
              required
            />
          </div>
          <div className="mb-3 text-center">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={formData.submitInProgress}
            >
              Anmelden
            </button>
          </div>
          {errorBanner}
          {spinner}
        </form>
      </BorderedDiv>
    </CenteredMain>
  );
}

export function SigninConfirm() {
  return (
    <CenteredMain>
      <BorderedDiv>
        <h1 className="mb-5 text-center">Willkommen zurück!</h1>
        <p>
          Sie erhalten in Kürze eine Email, über die Sie sich anmelden können.
        </p>
      </BorderedDiv>
    </CenteredMain>
  );
}
