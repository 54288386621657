export const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );

export const squareIconButtonStyle: React.CSSProperties = {
  height: 48,
  width: 48,
  fontSize: 24,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
