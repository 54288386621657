import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Landing from "./landing";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { useCookies } from "react-cookie";
import Navbar from "./navbar";
import { Impressum } from "./impressum";
import { Datenschutz } from "./datenschutz";
import { Agb } from "./agb";
import { useLocation } from "wouter";
import { Signin, SigninConfirm } from "./signin";
import { Signup, SignupConfirm } from "./signup";
import Footer from "./footer";
import { HomeElement } from "./home";
import { Route, RouteKind, parseRoute, navigate } from "./route";
import { ProtocolEditElement } from "./protocol-edit";
import { LinkQrCodeElement } from "./link-qr-code-element";
import { Protocol } from "./bindings/Protocol";
import { Protocols } from "./bindings/Protocols";
import { getProtocols } from "./api/protocol";
import { SyncStateElement } from "./fetch-queue";

// Check session on load. If the session is not valid, the response will unset session cookies.
(async function () {
  try {
    await fetch("/api/session", {
      method: "POST",
    });
  } catch (err) {}
})();

function isSignedOutOnlyRoute(route: Route): boolean {
  switch (route.kind) {
    case RouteKind.Home:
      return false;
    case RouteKind.Impressum:
      return false;
    case RouteKind.Datenschutz:
      return false;
    case RouteKind.Agb:
      return false;
    case RouteKind.Signup:
      return true;
    case RouteKind.SignupConfirm:
      return true;
    case RouteKind.Signin:
      return true;
    case RouteKind.SigninConfirm:
      return true;
    case RouteKind.Protocol:
      return false;
    case RouteKind.ProtocolView:
      return false;
    case RouteKind.LinkQrCode:
      return false;
    default: {
      const exhaustive: never = route;
      throw new Error(`Unhandled: ${exhaustive}`);
    }
  }
}

function isSignedInOnlyRoute(route: Route): boolean {
  switch (route.kind) {
    case RouteKind.Home:
      return false;
    case RouteKind.Impressum:
      return false;
    case RouteKind.Datenschutz:
      return false;
    case RouteKind.Agb:
      return false;
    case RouteKind.Signup:
      return false;
    case RouteKind.SignupConfirm:
      return false;
    case RouteKind.Signin:
      return false;
    case RouteKind.SigninConfirm:
      return false;
    case RouteKind.Protocol:
      return true;
    case RouteKind.ProtocolView:
      return false;
    case RouteKind.LinkQrCode:
      return true;
    default: {
      const exhaustive: never = route;
      throw new Error(`Unhandled: ${exhaustive}`);
    }
  }
}

function Root() {
  const cookies = useCookies(["user"])[0];
  const signedIn = cookies.user != null;
  const [location] = useLocation();

  const [protocols, setProtocols] = useState<Protocols>({});
  useEffect(() => {
    let cancel = false;
    (async () => {
      while (!cancel) {
        if (cookies.user != null && document.visibilityState === "visible") {
          try {
            setProtocols(await getProtocols());
          } catch (error) {
            // TODO: Show an error.
            console.error("Failed to get protocols", error);
          }
        }
        // One poll every 10 seconds.
        await new Promise((resolve) => setTimeout(resolve, 10000));
      }
    })();

    return () => {
      cancel = true;
    };
  }, [cookies]);

  const route: Route | null = (() => {
    try {
      return parseRoute(location);
    } catch (err) {
      // TODO: 404 page?
      return null;
    }
  })();

  const shouldRedirectHome =
    route == null ||
    (signedIn && isSignedOutOnlyRoute(route)) ||
    (!signedIn && isSignedInOnlyRoute(route));

  if (shouldRedirectHome) {
    navigate({ kind: RouteKind.Home });
  }

  const routeElement = (() => {
    switch (route?.kind) {
      case undefined:
        return null;
      case RouteKind.Home:
        return signedIn ? (
          <HomeElement protocols={protocols} setProtocols={setProtocols} />
        ) : (
          <Landing />
        );
      case RouteKind.Impressum:
        return <Impressum />;
      case RouteKind.Datenschutz:
        return <Datenschutz />;
      case RouteKind.Agb:
        return <Agb />;
      case RouteKind.Signup:
        return <Signup prefillEmail={route.prefillEmail} />;
      case RouteKind.SignupConfirm:
        return <SignupConfirm />;
      case RouteKind.Signin:
        return <Signin />;
      case RouteKind.SigninConfirm:
        return <SigninConfirm />;
      case RouteKind.Protocol: {
        const protocolId = route.protocolId;
        const protocol = protocols[protocolId];
        if (protocol == null) {
          return (
            <div>
              TODO: We're loading, or perhaps the protocol doesn't exist
            </div>
          );
        }

        function setProtocol(protocol: Protocol) {
          setProtocols({
            ...protocols,
            [protocolId]: protocol,
          });
        }
        return (
          <ProtocolEditElement
            protocolId={protocolId}
            protocol={protocol}
            setProtocol={setProtocol}
          />
        );
      }
      case RouteKind.ProtocolView:
        throw new Error("TODO: Remove this route.");
      case RouteKind.LinkQrCode:
        return (
          <LinkQrCodeElement
            protocols={protocols}
            qrCodeUuid={route.qrCodeUuid}
          />
        );
      default: {
        const exhaustive: never = route;
        throw new Error(`Unhandled: ${exhaustive}`);
      }
    }
  })();

  return (
    <>
      <SyncStateElement />
      <Navbar />

      {routeElement}

      {!signedIn && <Footer />}
    </>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
);
