import { Car } from "./bindings/Car";
import { CarPatch } from "./bindings/CarPatch";
import { ProtocolItem } from "./bindings/ProtocolItem";
import { ProtocolItemInfo } from "./bindings/ProtocolItemInfo";
import { ProtocolItemInfoPatch } from "./bindings/ProtocolItemInfoPatch";
import { ProtocolItemPatch } from "./bindings/ProtocolItemPatch";
import { ProtocolSigns } from "./bindings/ProtocolSigns";
import { ProtocolSignsPatch } from "./bindings/ProtocolSignsPatch";
import { QrCode } from "./bindings/QrCode";
import { QrCodePatch } from "./bindings/QrCodePatch";

export const emptyProtocolSignsPatch: ProtocolSignsPatch = {
  noStoppingSignLeft: null,
  noStoppingSignRight: null,
  noStoppingSignLeftRight: null,
  noParkingSignLeft: null,
  noParkingSignRight: null,
  noParkingSignLeftRight: null,
};

export function applyProtocolSignsPatch(
  signs: ProtocolSigns,
  patch: ProtocolSignsPatch,
): ProtocolSigns {
  return {
    noStoppingSignLeft: patch.noStoppingSignLeft ?? signs.noStoppingSignLeft,
    noStoppingSignRight: patch.noStoppingSignRight ?? signs.noStoppingSignRight,
    noStoppingSignLeftRight:
      patch.noStoppingSignLeftRight ?? signs.noStoppingSignLeftRight,
    noParkingSignLeft: patch.noParkingSignLeft ?? signs.noParkingSignLeft,
    noParkingSignRight: patch.noParkingSignRight ?? signs.noParkingSignRight,
    noParkingSignLeftRight:
      patch.noParkingSignLeftRight ?? signs.noParkingSignLeftRight,
  };
}

export const emptyCarPatch: CarPatch = {
  licensePlate: null,
  brand: null,
  color: null,
};

export function applyCarPatch(car: Car, patch: CarPatch): Car {
  return {
    licensePlate: patch.licensePlate ?? car.licensePlate,
    brand: patch.brand ?? car.brand,
    color: patch.color ?? car.color,
  };
}

export function applyQrCodePatch(qrCode: QrCode, patch: QrCodePatch): QrCode {
  return {
    uuid: patch.uuid ?? qrCode.uuid,
    linkState: patch.linkState ?? qrCode.linkState,
  };
}

export const emptyProtocolItemPatch: ProtocolItemPatch = {
  info: null,
  imageId: null,
};

export function applyProtocolItemInfoPatch(
  info: ProtocolItemInfo,
  patch: ProtocolItemInfoPatch,
): ProtocolItemInfo {
  if ("set" in patch) {
    return patch.set;
  } else if ("updateCar" in patch) {
    return "car" in info
      ? { car: applyCarPatch(info.car, patch.updateCar) }
      : info;
  } else if ("updateSign" in patch) {
    return "sign" in info ? { sign: patch.updateSign } : info;
  } else {
    const exhaustive: never = patch;
    throw new Error(`Unhandled: ${exhaustive}`);
  }
}

export function applyProtocolItemPatch(
  item: ProtocolItem,
  patch: ProtocolItemPatch,
): ProtocolItem {
  return {
    imageId: patch.imageId ?? item.imageId,
    info:
      item.info != null && patch.info != null
        ? applyProtocolItemInfoPatch(item.info, patch.info)
        : item.info,
    createdAt: item.createdAt,
  };
}
