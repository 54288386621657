import { useState } from "react";
import { postLinkQrCode } from "./api/qr-code";
import { ProtocolId } from "./bindings/ProtocolId";
import { Protocols } from "./bindings/Protocols";

export type LinkQrCodeElementProps = {
  qrCodeUuid: string;
  protocols: Protocols;
};

enum SubmitState {
  SUBMITTING,
  SUBMITTED,
  FAILED,
}

export function LinkQrCodeElement({
  qrCodeUuid,
  protocols,
}: LinkQrCodeElementProps) {
  const [selectedProtocol, setSelectedProtocol] = useState<ProtocolId | null>(
    null,
  );

  const [submitState, setSubmitState] = useState<SubmitState | null>(null);

  function handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    if (event.target.value === "") {
      setSelectedProtocol(null);
      return;
    }
    const protocolId = event.target.value;
    setSelectedProtocol(protocolId);
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (selectedProtocol == null) {
      setSubmitState(SubmitState.FAILED);
      return;
    }

    setSubmitState(SubmitState.SUBMITTING);
    try {
      await postLinkQrCode(qrCodeUuid, selectedProtocol);
      setSubmitState(SubmitState.SUBMITTED);
    } catch (err) {
      console.error("Failed to link QR code", err);
      setSubmitState(SubmitState.FAILED);
    }
  }

  return (
    <main style={{ margin: 8 }}>
      <h1>QR-Code mit Protokoll verbinden</h1>

      {protocols != null && (
        <form
          style={{ display: "flex", flexDirection: "column", gap: 16 }}
          onSubmit={handleSubmit}
        >
          <label>
            Wählen Sie das Protokoll, das Sie mit dem QR-Code verbinden möchten:
            <select
              className="form-select"
              value={selectedProtocol ?? undefined}
              required
              onChange={handleChange}
            >
              {Object.entries(protocols).map(
                ([protocolId, protocolSummary]) => {
                  if (protocolSummary == null) {
                    // This should be impossible; the ts-rs exported type signature incorrectly allows
                    // the value of the exported map to be null.
                    throw new Error(`Protocol with ID ${protocolId} not found`);
                  }

                  return (
                    <option key={protocolId} value={protocolId}>
                      {protocolSummary.address},{" "}
                      {(() => {
                        const monthName = new Date(protocolSummary.date || "")
                          .toLocaleString("de-DE", { month: "long" })
                          .slice(0, 3);
                        const day = new Date(
                          protocolSummary.date || "",
                        ).getDate();
                        return `${day}. ${monthName}`;
                      })()}
                      , {protocolSummary.recorderName}
                    </option>
                  );
                },
              )}
            </select>
          </label>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <input
              type="submit"
              value="QR-Code verbinden"
              className="btn btn-dark"
              disabled={
                submitState === SubmitState.SUBMITTING ||
                selectedProtocol == null
              }
            />
          </div>
          {(() => {
            switch (submitState) {
              case null:
                return null;
              case SubmitState.SUBMITTING:
                return <div className="spinner-border"></div>;
              case SubmitState.SUBMITTED:
                return (
                  <div className="alert alert-success" role="alert">
                    Der QR-Code wurde erfolgreich mit dem Protokoll verbunden.
                  </div>
                );
              case SubmitState.FAILED:
                return (
                  <div className="alert alert-danger" role="alert">
                    Ein Fehler ist aufgetreten. Bitte versuchen Sie es später
                    erneut.
                  </div>
                );
              default: {
                const exhaustive: never = submitState;
                throw new Error(`Unhandled submit state: ${exhaustive}`);
              }
            }
          })()}
        </form>
      )}
    </main>
  );
}
