import React, { useState, ChangeEvent, useEffect } from "react";
import {
  downloadLinkHtml,
  emptyProtocolPatch,
  patchProtocol,
} from "./api/protocol";
import noStopping from "./no-stopping.png";
import noStoppingLeft from "./no-stopping-left.png";
import noStoppingRight from "./no-stopping-right.png";
import noStoppingLeftRight from "./no-stopping-left-right.png";
import noParking from "./no-parking.png";
import noParkingLeft from "./no-parking-left.png";
import noParkingRight from "./no-parking-right.png";
import noParkingLeftRight from "./no-parking-left-right.png";
import { QRCodeScanElement } from "./qr-code-scan";
import { postLinkQrCode } from "./api/qr-code";
import { navigate, RouteKind } from "./route";
import { ProtocolSigns } from "./bindings/ProtocolSigns";
import { ProtocolId } from "./bindings/ProtocolId";
import { Protocol } from "./bindings/Protocol";
import { Car } from "./bindings/Car";
import { ProtocolSignsPatch } from "./bindings/ProtocolSignsPatch";
import { imageUrl, postImage } from "./api/image";
import { squareIconButtonStyle } from "./style";
import { ProtocolItemPatch } from "./bindings/ProtocolItemPatch";
import { CarPatch } from "./bindings/CarPatch";
import { ProtocolItem } from "./bindings/ProtocolItem";
import { ProtocolItemId } from "./bindings/ProtocolItemId";
import {
  applyProtocolItemPatch,
  applyProtocolSignsPatch,
  emptyCarPatch,
  emptyProtocolItemPatch,
  emptyProtocolSignsPatch,
} from "./patch";
import { Sign } from "./bindings/Sign";
import { ProtocolItemInfo } from "./bindings/ProtocolItemInfo";
import jsQR from "jsqr";
import SignSelect from "./sign-select";
import { QrCode } from "./bindings/QrCode";

// An random UUID v4:
function randomUuidV4(): string {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

function blurOnEnter(event: React.KeyboardEvent<HTMLInputElement>) {
  if (event.key === "Enter") {
    event.currentTarget.blur();
  }
}

type ProtocolEditElementProps = {
  protocolId: ProtocolId;
  protocol: Protocol;
  setProtocol: (protocol: Protocol) => void;
};

// A hook to create a state variable that reflects the prop value but can be edited.
//
// Changes flow in only one direction: If the prop value changes, then this hook will update the
// state variable, but if the state variable changes, then this hook will not update the prop
// value.
// This is useful to allow editing a prop value without changing the prop value itself.
//
// Note: Does not work properly with deep objects, only with primitive values, since it uses shallow
// equality.
function useEditingValue<T>(propValue: T): [T, (value: T) => void] {
  const [value, setValue] = useState<T>(propValue);

  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  return [
    value,
    (value: T) => {
      setValue(value);
    },
  ];
}

function toLocalTime(date: Date): string {
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
}

function setLocalTime(date: Date, time: string) {
  const [hours, minutes] = time.split(":").map((part) => parseInt(part, 10));
  date.setHours(hours);
  date.setMinutes(minutes);
}

function toLocalDate(date: Date): string {
  const year = date.getFullYear().toString().padStart(4, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function setLocalDate(date: Date, localDate: string) {
  const [year, month, day] = localDate
    .split("-")
    .map((part) => parseInt(part, 10));
  date.setFullYear(year);
  date.setMonth(month - 1);
  date.setDate(day);
}

type CarElementProps = {
  car: Car;
  patchCar: (patch: CarPatch) => void;
};

function CarElement({ car, patchCar }: CarElementProps): JSX.Element {
  const [licensePlate, setLicensePlate] = useEditingValue(
    car.licensePlate ?? "",
  );
  const [brand, setBrand] = useEditingValue(car.brand ?? "");
  const [color, setColor] = useEditingValue(car.color ?? "");

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: 4,
      }}
    >
      <label>
        Nummernschild
        <input
          type="text"
          value={licensePlate ?? ""}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setLicensePlate(e.target.value);
          }}
          onBlur={() => {
            patchCar({
              ...emptyCarPatch,
              licensePlate: licensePlate,
            });
          }}
          onKeyPress={blurOnEnter}
          style={{
            padding: "10px",
            borderRadius: "4px",
            border: "1px solid #ccc",
            width: "100%",
          }}
        />
      </label>
      <label>
        Automarke
        <input
          type="text"
          value={brand ?? ""}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setBrand(e.target.value);
          }}
          onBlur={() => {
            patchCar({ ...emptyCarPatch, brand: brand });
          }}
          onKeyPress={blurOnEnter}
          style={{
            padding: "10px",
            borderRadius: "4px",
            border: "1px solid #ccc",
            width: "100%",
          }}
        />
      </label>
      <label>
        Farbe
        <input
          type="text"
          value={color ?? ""}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setColor(e.target.value);
          }}
          onBlur={() => {
            patchCar({ ...emptyCarPatch, color: color });
          }}
          onKeyPress={blurOnEnter}
          style={{
            padding: "10px",
            borderRadius: "4px",
            border: "1px solid #ccc",
            width: "100%",
          }}
        />
      </label>
    </div>
  );
}

type ProtocolItemElementProps = {
  protocolItem: ProtocolItem;
  patchProtocolItem: (patch: ProtocolItemPatch) => void;
  deleteProtocolItem: () => void;
  imageDataUrl: string | null;
};

function ProtocolItemElement({
  protocolItem,
  patchProtocolItem,
  deleteProtocolItem,
  imageDataUrl,
}: ProtocolItemElementProps): JSX.Element {
  const imageId = protocolItem.imageId;
  const info = protocolItem.info;

  // Whether we allow the user to change the kind of the item.
  const allowItemKindSelect = (() => {
    if ("none" in info || "car" in info || "sign" in info) {
      return true;
    } else if ("qrCode" in info) {
      return false;
    } else {
      const exhaustive: never = info;
      throw new Error(`Unhandled: ${info}`);
    }
  })();

  return (
    <div
      style={{
        display: "flex",
        padding: "10px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#f9f9f9",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          gap: 10,
          marginRight: 10,
          flex: 1,
          maxWidth: 200,
        }}
      >
        <img
          src={
            imageDataUrl ?? (imageId != null ? imageUrl(imageId) : undefined)
          }
          alt="Auto"
          style={{
            width: "100%",
            borderRadius: "4px",
            objectFit: "cover",
          }}
        />
        <button
          className="btn btn-danger"
          onClick={(e) => {
            deleteProtocolItem();
          }}
          disabled={"qrCode" in info && info.qrCode.linkState !== "failed"}
        >
          Löschen
        </button>
      </div>
      <div
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          flex: 1,
          gap: 4,
        }}
      >
        <select
          className="form-select"
          style={{ width: "100%", fontSize: "1.25em", fontWeight: "bold" }}
          disabled={!allowItemKindSelect}
          onChange={(ev) => {
            const value = ev.target.value;
            const newInfo: ProtocolItemInfo = (() => {
              switch (value) {
                case "none":
                  return { none: [] };
                  break;
                case "car":
                  return { car: { licensePlate: "", brand: "", color: "" } };
                  break;
                case "sign":
                  return { sign: "other" };
                  break;
                case "qrCode":
                  throw new Error("QR-Code should not be selectable");
                default:
                  // The type system can't enforce that this is exhaustive, but it should be
                  // exhaustive.
                  throw new Error(`Unhandled value: ${value}`);
              }
            })();
            patchProtocolItem({
              ...emptyProtocolItemPatch,
              info: { set: newInfo },
            });
          }}
        >
          <option selected={"none" in info} value="none">
            Sonstiges
          </option>
          <option selected={"car" in info} value="car">
            Fahrzeug
          </option>
          <option selected={"sign" in info} value="sign">
            Schild
          </option>
          {"qrCode" in info && (
            <option selected={"qrCode" in info} value="qrCode">
              QR-Code
            </option>
          )}
        </select>

        {(() => {
          if ("car" in info) {
            function patchCar(carPatch: CarPatch) {
              const protocolItemPatch: ProtocolItemPatch = {
                ...emptyProtocolItemPatch,
                info: { updateCar: carPatch },
              };
              patchProtocolItem(protocolItemPatch);
            }
            return <CarElement car={info.car} patchCar={patchCar} />;
          } else if ("sign" in info) {
            function setSign(sign: Sign) {
              const protocolItemPatch: ProtocolItemPatch = {
                ...emptyProtocolItemPatch,
                info: { updateSign: sign },
              };
              patchProtocolItem(protocolItemPatch);
            }
            return <SignSelect value={info.sign} onChange={setSign} />;
          } else if ("none" in info) {
            return "";
          } else if ("qrCode" in info) {
            const qrCodeIcon = <i className="bi bi-qr-code"></i>;
            return (() => {
              switch (info.qrCode.linkState) {
                case "pending":
                  return <div className="spinner"></div>;
                case "success":
                  return <div className="alert alert-success">Verbunden</div>;
                case "failed":
                  // An error banner:
                  return (
                    <div className="alert alert-danger">
                      Verbindung fehlgeschlagen
                    </div>
                  );
                default: {
                  const exhaustive: never = info.qrCode.linkState;
                  throw new Error(`Unhandled: ${exhaustive}`);
                }
              }
            })();
          } else {
            const exhaustive: never = info;
            throw new Error(`Unhandled info type: ${exhaustive}`);
          }
        })()}
      </div>
    </div>
  );
}

enum QrCodeLinkState {
  NONE,
  FAILED,
  SUCCESS,
}

export function ProtocolEditElement({
  protocolId,
  protocol,
  setProtocol,
}: ProtocolEditElementProps): JSX.Element {
  const [address, setAddress] = useEditingValue(protocol.address);
  const [date, setDate] = useEditingValue(protocol.date);
  const [recorderName, setRecorderName] = useEditingValue(
    protocol.recorderName,
  );
  const [imageDataUrls, setImageDataUrls] = useState<
    Record<ProtocolItemId, string>
  >({});

  const shouldScrollToLastCar = React.useRef<boolean>(false);

  const [showQrCodeModal, setShowQrCodeModal] = useState<boolean>(false);
  const [qrCodeLinkState, setQrCodeLinkState] = useState<QrCodeLinkState>(
    QrCodeLinkState.NONE,
  );

  const [showFinalizeModal, setShowFinalizeModal] = useState<boolean>(false);

  const isMissingItems = Object.keys(protocol.protocolItems).length === 0;
  const isMissingAddress = protocol.address == null || protocol.address === "";
  const isMissingDate = protocol.date == null || protocol.date === "";
  const isMissingRecorderName =
    protocol.recorderName == null || protocol.recorderName === "";

  const isMissingData =
    isMissingItems ||
    isMissingAddress ||
    isMissingDate ||
    isMissingRecorderName;

  async function handleCapture(event: ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      await new Promise((resolve, reject) => {
        reader.onloadend = resolve;
        reader.onerror = reject;
      });
      if (typeof reader.result !== "string") {
        // This should be impossible by definition of readAsAdataURL.
        throw new Error("Expected reader.result to be a string");
      }

      const img = new Image();
      img.src = reader.result;
      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
      });

      const canvas = document.createElement("canvas");

      const maxWidth = 800; // Define the maximum width you want
      const scaleFactor = maxWidth / img.width;
      canvas.width = maxWidth;
      canvas.height = img.height * scaleFactor;
      const ctx = canvas.getContext("2d");
      if (ctx == null) {
        throw new Error("Failed to get 2d context");
      }

      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      let qrCodeInfo: QrCode | null = null;
      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const qrCode = jsQR(imageData.data, imageData.width, imageData.height);
      if (qrCode != null) {
        // Get the part after the last slash:
        const uuid = qrCode.data.split("/").pop();
        qrCodeInfo = {
          uuid: uuid ?? "",
          linkState: uuid != null ? "pending" : "failed",
        };
      }

      const imageBlob = await new Promise<Blob | null>((resolve) => {
        canvas.toBlob(resolve, "image/jpeg", 0.8);
      });
      if (imageBlob == null) {
        throw new Error("Failed to convert canvas to blob");
      }
      const imageDataUrl = canvas.toDataURL("image/jpeg", 0.8);

      const newImageId = randomUuidV4();
      postImage(newImageId, imageBlob);

      const protocolItemId = randomUuidV4();
      const createdAt = new Date().toISOString();
      setImageDataUrls({ ...imageDataUrls, [protocolItemId]: imageDataUrl });
      const newProtocolItem: ProtocolItem = {
        imageId: newImageId,
        info: qrCodeInfo != null ? { qrCode: qrCodeInfo } : { none: [] },
        createdAt,
      };

      patchProtocol(protocolId, {
        ...emptyProtocolPatch,
        protocolItems: {
          [protocolItemId]: {
            kind: "add",
            value: newProtocolItem,
          },
        },
      });
      setProtocol({
        ...protocol,
        protocolItems: {
          ...protocol.protocolItems,
          [protocolItemId]: newProtocolItem,
        },
      });

      shouldScrollToLastCar.current = true;
    }
  }

  return (
    <main
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        justifyContent: "flex-start",
        gap: 12,
        paddingTop: 16,
        paddingBottom: 64,
        paddingLeft: 4,
        paddingRight: 4,
        width: "min(100%, 768px)",
        margin: "auto",
      }}
    >
      <h2
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        Aufstellprotokoll
      </h2>

      <div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
        <label>
          Adresse
          <input
            type="text"
            value={address ?? ""}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setAddress(e.target.value);
            }}
            onBlur={() => {
              patchProtocol(protocolId, { ...emptyProtocolPatch, address });
              setProtocol({ ...protocol, address });
            }}
            style={{
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              width: "100%",
            }}
          />
        </label>

        <div style={{ display: "flex", flexDirection: "row", gap: 16 }}>
          <label style={{ flex: 1 }}>
            Datum
            <input
              type="date"
              value={date != null ? toLocalDate(new Date(date)) : ""}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                try {
                  const newDate = new Date(date ?? new Date());
                  setLocalDate(newDate, e.target.value);
                  setDate(newDate.toISOString());
                } catch (error) {
                  console.error("Failed to parse date", error);
                }
              }}
              onBlur={() => {
                patchProtocol(protocolId, { ...emptyProtocolPatch, date });
                setProtocol({ ...protocol, date });
              }}
              style={{
                padding: "10px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                width: "100%",
              }}
            />
          </label>
          <label style={{ flex: 1 }}>
            Uhrzeit
            <input
              type="time"
              value={date != null ? toLocalTime(new Date(date)) : ""}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                try {
                  const newDate = new Date(date ?? new Date());
                  setLocalTime(newDate, e.target.value);
                  setDate(newDate.toISOString());
                } catch (error) {
                  console.error("Failed to parse date", error);
                }
              }}
              onBlur={() => {
                patchProtocol(protocolId, { ...emptyProtocolPatch, date });
                setProtocol({ ...protocol, date });
              }}
              style={{
                padding: "10px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                width: "100%",
              }}
            />
          </label>
        </div>

        <label>
          Protokollant
          <input
            type="text"
            value={recorderName ?? ""}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setRecorderName(e.target.value);
            }}
            onBlur={() => {
              patchProtocol(protocolId, {
                ...emptyProtocolPatch,
                recorderName,
              });
              setProtocol({ ...protocol, recorderName });
            }}
            style={{
              padding: "10px",
              borderRadius: "4px",
              border: "1px solid #ccc",
              width: "100%",
            }}
          />
        </label>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "stretch",
          }}
        >
          {Object.keys(protocol.protocolItems).length === 0 && (
            <div className="alert alert-warning">Noch keine Bilder</div>
          )}
          {Object.entries(protocol.protocolItems)
            .map(function ([protocolItemId, protocolItem]): [
              ProtocolItemId,
              ProtocolItem,
            ] {
              if (protocolItem == null) {
                // This is necessary because of a typing bug in ts-rs: This can never be
                // null/undefined in the Rust type that we generate the typescript type from.
                throw new Error("Item is null");
              }

              return [protocolItemId, protocolItem];
            })
            .sort(([, a], [, b]) => (a.createdAt < b.createdAt ? -1 : 1))
            .map(([protocolItemId, protocolItem]) => {
              function patch(patch: ProtocolItemPatch) {
                if (protocol == null) {
                  throw new Error("TODO: Verify that this is impossible");
                }

                patchProtocol(protocolId, {
                  ...emptyProtocolPatch,
                  protocolItems: {
                    [protocolItemId]: {
                      kind: "update",
                      patch,
                    },
                  },
                });
                setProtocol({
                  ...protocol,
                  protocolItems: {
                    ...protocol.protocolItems,
                    [protocolItemId]: applyProtocolItemPatch(
                      protocolItem,
                      patch,
                    ),
                  },
                });
              }

              function delete_() {
                if (protocol == null) {
                  throw new Error("TODO: Verify that this is impossible");
                }

                if (protocolItem == null) {
                  throw new Error(
                    "protocolItem == null is impossible by the Rust type signature",
                  );
                }

                setProtocol({
                  ...protocol,
                  protocolItems: Object.fromEntries(
                    Object.entries(protocol.protocolItems).filter(
                      ([key, _]) => key !== protocolItemId,
                    ),
                  ),
                });
                patchProtocol(protocolId, {
                  ...emptyProtocolPatch,
                  protocolItems: {
                    [protocolItemId]: {
                      kind: "remove",
                    },
                  },
                });
              }

              return (
                <ProtocolItemElement
                  imageDataUrl={imageDataUrls[protocolItemId]}
                  key={protocolItemId}
                  protocolItem={protocolItem}
                  patchProtocolItem={patch}
                  deleteProtocolItem={delete_}
                />
              );
            })}
          {(() => {
            switch (qrCodeLinkState) {
              case QrCodeLinkState.NONE:
                return null;
              case QrCodeLinkState.FAILED:
                return (
                  <div className="alert alert-danger">
                    QR-Code-Verbindung fehlgeschlagen
                  </div>
                );
              case QrCodeLinkState.SUCCESS:
                return (
                  <div className="alert alert-success">
                    QR-Code-Verbindung erfolgreich
                  </div>
                );
              default: {
                const exhaustive: never = qrCodeLinkState;
                throw new Error(`Unreachable: ${exhaustive}`);
              }
            }
          })()}

          <div
            style={{
              position: "fixed",
              bottom: 0,
              left: 0,
              width: "100%",
              height: 64,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
              zIndex: 1000,
              backgroundColor: "white",
              borderTop: "1px solid #ddd",
            }}
          >
            <a
              href={downloadLinkHtml(protocolId)}
              target="_blank"
              rel="noreferrer"
              style={squareIconButtonStyle}
              className="btn btn-dark"
            >
              <i className="bi bi-eye"></i>
            </a>
            <button
              style={squareIconButtonStyle}
              className="btn btn-dark"
              onClick={() => {
                setShowFinalizeModal(true);
              }}
            >
              <i className="bi bi-check"></i>
            </button>

            <div>
              <label
                htmlFor="cameraInput"
                style={squareIconButtonStyle}
                className="btn btn-dark"
              >
                <i className="bi bi-camera"></i>
              </label>
              <input
                id="cameraInput"
                type="file"
                accept="image/*"
                capture="environment"
                onChange={handleCapture}
                style={{ display: "none" }}
              />
            </div>
          </div>
          <div></div>
        </div>
      </div>
      {showFinalizeModal && (
        <div className="modal fade show d-block" tabIndex={-1} role="dialog">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Protokoll abschließen</h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setShowFinalizeModal(false)}
                ></button>
              </div>
              {isMissingData && (
                <div
                  className="modal-body"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div className="text-danger">Fehlende Daten:</div>
                  <ul className="text-danger">
                    {isMissingRecorderName && <li>Protokollant</li>}
                    {isMissingDate && <li>Datum</li>}
                    {isMissingItems && <li>Bilder</li>}
                    {isMissingAddress && <li>Adresse</li>}
                  </ul>
                  Trotzdem abschließen?
                </div>
              )}
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowFinalizeModal(false)}
                >
                  Abbrechen
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    navigate({ kind: RouteKind.Home });
                  }}
                >
                  Abschließen
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </main>
  );
}
