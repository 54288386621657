import { PostInvitePayload } from "../bindings/PostInvitePayload";

export async function postInvite(payload: PostInvitePayload): Promise<void> {
  const response = await fetch("/api/invite", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    throw new Error("Failed to invite user");
  }
}
