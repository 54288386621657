import { fetchGetAdaptive } from "../fetch-queue";

export async function getUserName(): Promise<string | null> {
  const response = await fetchGetAdaptive(`/api/user-name`);

  if (!response.ok) {
    throw new Error("Failed to get user name");
  }

  return response.json();
}
