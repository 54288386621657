import { useEffect, useState } from "react";

const dbName = "RequestQueue";
const storeName = "requests";

let db: IDBDatabase | null = null;

let isQueueEmpty = false;
let isProcessingQueue = false;

const onQueueEmptyCallbacks: (() => void)[] = [];
const onQueueNonEmptyCallbacks: (() => void)[] = [];
const onErrorCallbacks: (() => void)[] = [];

function onQueueEmpty(callback: () => void): void {
  if (isQueueEmpty) {
    callback();
  } else {
    onQueueEmptyCallbacks.push(callback);
  }
}
function onQueueNonEmpty(callback: () => void): void {
  if (!isQueueEmpty) {
    callback();
  } else {
    onQueueNonEmptyCallbacks.push(callback);
  }
}

function fireQueueEmpty(): void {
  for (const callback of onQueueEmptyCallbacks) {
    callback();
  }
  onQueueEmptyCallbacks.length = 0;
}
function fireQueueNonEmpty(): void {
  for (const callback of onQueueNonEmptyCallbacks) {
    callback();
  }
  onQueueNonEmptyCallbacks.length = 0;
}
function fireError(): void {
  for (const callback of onErrorCallbacks) {
    callback();
  }
  onErrorCallbacks.length = 0;
}

function openDatabase(): void {
  const request = indexedDB.open(dbName, 1);

  request.onerror = () => {
    console.error("Failed to open database", request.error);
  };

  request.onsuccess = (event) => {
    if (
      event.target == null ||
      !("result" in event.target) ||
      !(event.target.result instanceof IDBDatabase)
    ) {
      throw new Error(`Invalid success event: ${event}`);
    }
    const theDb = event.target.result;
    theDb
      .transaction(storeName, "readwrite")
      .objectStore(storeName)
      .count().onsuccess = (event) => {
      if (event.target == null) {
        throw new Error(`Invalid count event: ${event}`);
      }
      if (
        !("result" in event.target) ||
        typeof event.target.result !== "number"
      ) {
        throw new Error(`Invalid count event: ${event}`);
      }

      const count = event.target.result;
      isQueueEmpty = count === 0;
      db = theDb;
      processQueue();
    };
  };

  request.onupgradeneeded = (event) => {
    if (
      event.target == null ||
      !("result" in event.target) ||
      !(event.target.result instanceof IDBDatabase)
    ) {
      throw new Error(`Invalid onupgradneeded event: ${event}`);
    }
    const theDb = (event.target as IDBOpenDBRequest).result;
    theDb.createObjectStore(storeName, { autoIncrement: true });
    isQueueEmpty = true;
  };
}
openDatabase();

async function destroyDatabase(): Promise<void> {
  if (db) {
    db.close();
    db = null;
  }

  const request = indexedDB.deleteDatabase(dbName);
  await new Promise<Event>((resolve, reject) => {
    request.onsuccess = resolve;
    request.onerror = reject;
  });
}

function enqueue(url: string, options: RequestInit): void {
  if (db == null) {
    console.error("Database not initialized");
    return;
  }

  if (isQueueEmpty) {
    fireQueueNonEmpty();
  }
  isQueueEmpty = false;
  const transaction = db.transaction(storeName, "readwrite");
  const store = transaction.objectStore(storeName);
  store.add({ url, options });
}

async function processQueue(): Promise<void> {
  if (!db) {
    console.error("Database not initialized");
    return;
  }

  if (isProcessingQueue) {
    return;
  }
  isProcessingQueue = true;

  const transaction = db.transaction(storeName, "readwrite");
  const store = transaction.objectStore(storeName);
  const request = store.openCursor();

  request.onerror = () => {
    console.error("Failed to open cursor", request.error);
    isProcessingQueue = false;
  };
  request.onsuccess = async (event) => {
    const cursor = (event.target as IDBRequest<IDBCursorWithValue>).result;
    if (cursor == null) {
      isProcessingQueue = false;
      isQueueEmpty = true;
      fireQueueEmpty();
      return;
    }

    const { url, options } = cursor.value;
    let response: Response | null = null;
    try {
      response = await fetch(url, options);
    } catch (err) {
      // We're assuming that this must be a network error.
      // TODO: Are there conditions that aren't network errors but result in exceptions during fetch?
      if (navigator.onLine) {
        // We got a network error, but we're supposedly online.
        console.warn(
          "Network error but navigator.online is true, retrying later",
        );
        // There's not a lot we can do apart from waiting a bit before retrying.
        // TODO: Make sure we don't end up in a retry loop.
        setTimeout(processQueue, 1000);
      }
      isProcessingQueue = false;
      return;
    }

    if (!response.ok) {
      console.error(`Request failed with status ${response.status}`);
      removeRequest(cursor.key);
      fireError();
      setTimeout(processQueue, 0);
    }

    removeRequest(cursor.key);

    isProcessingQueue = false;
    setTimeout(processQueue, 0);
  };
}

window.addEventListener("online", processQueue);

function removeRequest(key: IDBValidKey): void {
  if (!db) {
    console.error("Database not initialized");
    return;
  }

  const transaction = db.transaction(storeName, "readwrite");
  const store = transaction.objectStore(storeName);
  store.delete(key);
}

export async function fetchMutatingAdaptive(
  url: string,
  options: RequestInit,
): Promise<void> {
  enqueue(url, options);
  processQueue();
}

export async function fetchGetAdaptive(
  url: string,
  options?: RequestInit,
): Promise<Response> {
  if (options?.signal != null) {
    throw new Error("Setting `signal` is not supported for fetchGetAdaptive");
  }
  if (options?.method != null && options.method !== "GET") {
    throw new Error("Only GET requests are supported for fetchGetAdaptive");
  }

  while (true) {
    await new Promise<void>((resolve) => {
      onQueueEmpty(resolve);
    });

    const abortController = new AbortController();
    const signal = abortController.signal;
    onQueueNonEmpty(() => {
      abortController.abort();
    });
    try {
      const response = await fetch(url, { ...options, signal });
      return response;
    } catch (err) {
      if (err instanceof Error && err.name === "AbortError") {
        console.log("Request aborted due to other requests");
      } else {
        throw err;
      }
    }
  }
}

export function SyncStateElement(): JSX.Element {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const [uploadsAreSlow, setUploadsAreSlow] = useState(false);

  const [errorOccurred, setErrorOccurred] = useState(false);

  // Keep the isOnline state in sync with the navigator.onLine property.
  useEffect(() => {
    function onOnline() {
      setIsOnline(true);
    }
    function onOffline() {
      setIsOnline(false);
    }

    window.addEventListener("online", onOnline);
    window.addEventListener("offline", onOffline);

    return () => {
      window.removeEventListener("online", onOnline);
      window.removeEventListener("offline", onOffline);
    };
  }, []);

  // uploadsAreSlow should flip to true after 5 seconds of uploading.
  useEffect(() => {
    let cont = true;
    (async () => {
      while (cont) {
        await new Promise<void>((resolve) => {
          onQueueNonEmpty(resolve);
        });
        try {
          await new Promise<void>((resolve, reject) => {
            setTimeout(resolve, 5000);
            onQueueEmpty(reject);
          });
          setUploadsAreSlow(true);
        } catch (err) {
          setUploadsAreSlow(false);
        }
      }
    })();
  }, []);

  useEffect(() => {
    onErrorCallbacks.push(() => {
      setErrorOccurred(true);
    });
  }, []);

  async function handleReloadClick(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    await destroyDatabase();
    window.location.reload();
  }

  if (errorOccurred) {
    return (
      <div className="alert alert-danger">
        Ein Fehler ist aufgetreten. Bitte laden Sie die Seite neu.{" "}
        <button onClick={handleReloadClick} className="btn btn-dark">
          Neu laden
        </button>
      </div>
    );
  }

  if (!isOnline) {
    return (
      <div className="alert alert-warning">
        Sie sind offline. Änderungen werden gespeichert, sobald Sie wieder
        online sind.
      </div>
    );
  }

  if (uploadsAreSlow) {
    return (
      <div className="alert alert-warning">
        Änderungen werden gespeichert...{" "}
        <div className="spinner-border spinner-border-sm" role="status"></div>
      </div>
    );
  }

  return <div></div>;
}
