import { ImageId } from "../bindings/ImageId";
import { fetchMutatingAdaptive } from "../fetch-queue";

export async function postImage(
  imageId: ImageId,
  jpegBlob: Blob,
): Promise<void> {
  await fetchMutatingAdaptive(`/api/image/${imageId}`, {
    method: "POST",
    headers: {
      "Content-Type": "image/jpeg",
    },
    body: jpegBlob,
  });
}

export function imageUrl(imageId: ImageId): string {
  return `/api/image/${imageId}`;
}
