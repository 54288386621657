import { ProtocolId } from "./bindings/ProtocolId";

export enum RouteKind {
  Home = "home",
  Signup = "signup",
  SignupConfirm = "signupConfirm",
  Signin = "signin",
  SigninConfirm = "signinConfirm",
  Impressum = "impressum",
  Datenschutz = "datenschutz",
  Agb = "agb",
  Protocol = "protocol",
  ProtocolView = "protocolView",
  LinkQrCode = "linkQrCode",
}

export type Route =
  | { kind: RouteKind.Home }
  | { kind: RouteKind.Signup; prefillEmail: string }
  | { kind: RouteKind.SignupConfirm }
  | { kind: RouteKind.Signin }
  | { kind: RouteKind.SigninConfirm }
  | { kind: RouteKind.Impressum }
  | { kind: RouteKind.Datenschutz }
  | { kind: RouteKind.Agb }
  | { kind: RouteKind.Protocol; protocolId: ProtocolId }
  | { kind: RouteKind.ProtocolView; protocolId: ProtocolId }
  | { kind: RouteKind.LinkQrCode; qrCodeUuid: string };

function parseRouteImpl(location: string): Route {
  const segments = location.split("/");
  // Every location string begins with "/", so the first
  // part must be empty.
  const emptyFirst = segments.shift();
  if (emptyFirst !== "") {
    throw new Error(`Location does not start with /: ${location}`);
  }

  const route: Route = (() => {
    const head = segments.shift();
    switch (head) {
      case undefined:
      case "":
        return { kind: RouteKind.Home };
      case "signup": {
        const second = segments.shift();
        switch (second) {
          case "confirm":
            return { kind: RouteKind.SignupConfirm };
          case undefined:
            return { kind: RouteKind.Signup, prefillEmail: "" };
          default:
            const defaultEmail: string = decodeURIComponent(second);
            return { kind: RouteKind.Signup, prefillEmail: defaultEmail };
        }
      }
      case "signin": {
        const second = segments.shift();
        switch (second) {
          case undefined:
            return { kind: RouteKind.Signin };
          case "confirm":
            return { kind: RouteKind.SigninConfirm };
          default:
            throw new Error(`Invalid signin route ${second}`);
        }
      }
      case "impressum":
        return { kind: RouteKind.Impressum };
      case "datenschutz":
        return { kind: RouteKind.Datenschutz };
      case "agb":
        return { kind: RouteKind.Agb };
      case "protocol": {
        const second = segments.shift();
        if (second === undefined) {
          throw new Error(`Missing protocol id for route ${head}`);
        }
        const protocolId = second;
        if (protocolId === null) {
          throw new Error(`Invalid protocol id for route ${head}: ${second}`);
        }
        return { kind: RouteKind.Protocol, protocolId };
      }
      case "protocol-view": {
        const second = segments.shift();
        if (second === undefined) {
          throw new Error(`Missing protocol id for route ${head}`);
        }
        const protocolId = second;
        if (protocolId === null) {
          throw new Error(`Invalid protocol id for route ${head}: ${second}`);
        }
        return { kind: RouteKind.ProtocolView, protocolId };
      }
      case "link-qr-code": {
        const second = segments.shift();
        if (second === undefined) {
          throw new Error(`Missing qr code uuid for route ${head}`);
        }
        return { kind: RouteKind.LinkQrCode, qrCodeUuid: second };
      }
      default:
        throw new Error(`Invalid top level route: ${head}`);
    }
  })();

  if (segments.length !== 0) {
    throw new Error(
      `Trailing segments for location "${location}" after parsed route ${route}: ${segments}`,
    );
  }

  return route;
}

function serializeRouteImpl(route: Route): string {
  switch (route.kind) {
    case RouteKind.Home:
      return "/";
    case RouteKind.Signup: {
      return (
        "/signup" +
        (route.prefillEmail ? `/${encodeURIComponent(route.prefillEmail)}` : "")
      );
    }
    case RouteKind.SignupConfirm:
      return "/signup/confirm";
    case RouteKind.Signin:
      return "/signin";
    case RouteKind.SigninConfirm:
      return "/signin/confirm";
    case RouteKind.Impressum:
      return "/impressum";
    case RouteKind.Datenschutz:
      return "/datenschutz";
    case RouteKind.Agb:
      return "/agb";
    case RouteKind.Protocol:
      return `/protocol/${route.protocolId}`;
    case RouteKind.ProtocolView:
      return `/protocol-view/${route.protocolId}`;
    case RouteKind.LinkQrCode:
      return `/link-qr-code/${route.qrCodeUuid}`;
    default: {
      const exhaustive: never = route;
      throw new Error(`Unhandled: ${exhaustive}`);
    }
  }
}

export function parseRoute(location: string): Route {
  const parsed = parseRouteImpl(location);

  // TODO: Do not do this in production.
  const serializedParsed = serializeRouteImpl(parsed);
  if (location !== serializedParsed) {
    throw new Error(
      `serializeRoute(parseRoute(location)) != location: ${location} !== ${serializedParsed}`,
    );
  }

  return parsed;
}

export function serializeRoute(route: Route): string {
  const serialized = serializeRouteImpl(route);

  // TODO: Do not do this in production.
  const parsedSerialized: Route = (() => {
    try {
      return parseRouteImpl(serialized);
    } catch (err) {
      throw new Error("parseRoute(serializeRoute(route)) failed", {
        cause: err,
      });
    }
  })();

  if (JSON.stringify(parsedSerialized) !== JSON.stringify(route)) {
    throw new Error(
      `parseRoute(serializeRoute(route)) !== route: ${JSON.stringify(
        parsedSerialized,
      )} !== ${JSON.stringify(route)}`,
    );
  }

  return serialized;
}

export function navigate(route: Route) {
  window.history.pushState(null, "", serializeRoute(route));
}
