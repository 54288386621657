import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  emptyProtocolPatch,
  patchProtocol,
  postProtocol,
} from "./api/protocol";
import { navigate, RouteKind } from "./route";
import { QrCodePrintElement } from "./qr-code-print";
import { useCookies } from "react-cookie";
import { postInvite } from "./api/invite";
import { ProtocolId } from "./bindings/ProtocolId";
import { UserType } from "./bindings/UserType";
import { GeoLocation } from "./bindings/GeoLocation";
import { Protocol } from "./bindings/Protocol";
import { Protocols } from "./bindings/Protocols";
import { ProtocolPatch } from "./bindings/ProtocolPatch";
import { isMobile, squareIconButtonStyle } from "./style";
import { getUserName } from "./api/user-name";

async function getCurrentPosition(): Promise<GeoLocation | null> {
  if (navigator.geolocation == null) {
    console.warn("Geolocation is not available");
    return null;
  }

  const geolocationPosition = await new Promise<GeolocationPosition>(
    (resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    },
  );
  return {
    latitude: geolocationPosition.coords.latitude,
    longitude: geolocationPosition.coords.longitude,
    accuracy: geolocationPosition.coords.accuracy,
  };
}

type ProtocolSummaryElementProps = {
  protocolId: ProtocolId;
  protocol: Protocol;
};
function ProtocolSummaryElement({
  protocolId,
  protocol,
}: ProtocolSummaryElementProps) {
  const { address, date, protocolItems, recorderName, orderNumber } = protocol;
  const itemCount = Object.entries(protocolItems).length;

  function handleOrderNumberBlur(event: React.FocusEvent<HTMLInputElement>) {
    const patch: ProtocolPatch = {
      ...emptyProtocolPatch,
      orderNumber: event.target.value,
    };
    patchProtocol(protocolId, patch);
  }

  function handleRowClick(
    ev: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
  ) {
    ev.preventDefault();
    navigate({
      kind: RouteKind.Protocol,
      protocolId,
    });
  }

  return (
    <tr
      onClick={handleRowClick}
      style={{
        cursor: "pointer",
        userSelect: "none",
        transition: "background-color 0.2s",
      }}
    >
      <td style={{ maxWidth: 100 }}>{address}</td>
      <td>{date != null ? new Date(date).toLocaleDateString() : ""}</td>
      {!isMobile && (
        <>
          <td>{recorderName}</td>
          <td>{itemCount}</td>
          <td>
            <input
              type="text"
              style={{ width: "100%", maxWidth: 150 }}
              defaultValue={orderNumber != null ? orderNumber : ""}
              onClick={(ev) => {
                // We stop propagation so that the click handler on the row doesn't trigger.
                ev.stopPropagation();
              }}
              onBlur={handleOrderNumberBlur}
            />
          </td>
        </>
      )}
    </tr>
  );
}

function InviteButton(): JSX.Element {
  const [showModal, setShowModal] = useState(false);
  const [inviteeEmail, setInviteeEmail] = useState("");
  const [inviteeName, setInviteeName] = useState("");
  const [showError, setShowError] = useState(false);

  const handleInvite = async () => {
    try {
      setShowError(false);
      await postInvite({ inviteeEmail, inviteeName });
      setShowModal(false);
      setInviteeEmail("");
    } catch (error) {
      console.error("Failed to invite user", error);
      setShowError(true);
    }
  };

  return (
    <div>
      <button className="btn btn-dark" onClick={() => setShowModal(true)}>
        Mitarbeiter einladen
      </button>

      {showModal && (
        <div className="modal fade show d-block" tabIndex={-1} role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Einladen</h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setShowModal(false)}
                ></button>
              </div>
              <div
                className="modal-body"
                style={{ display: "flex", flexDirection: "column", gap: 12 }}
              >
                {showError && (
                  <div className="alert alert-danger">
                    Ein Fehler ist aufgetreten. Ist die Email-Adresse korrekt?
                  </div>
                )}
                <input
                  type="email"
                  className="form-control"
                  placeholder="E-Mail-Adresse eingeben"
                  value={inviteeEmail}
                  onChange={(e) => setInviteeEmail(e.target.value)}
                  required
                />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  value={inviteeName}
                  onChange={(e) => setInviteeName(e.target.value)}
                  required
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}
                >
                  Abbrechen
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleInvite}
                >
                  Einladen
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

type HomeElementProps = {
  protocols: Protocols;
  setProtocols: (protocols: Protocols) => void;
};

export function HomeElement({ protocols, setProtocols }: HomeElementProps) {
  const [cookies] = useCookies(["user"]);
  const userType = cookies.user as UserType;

  async function handleAddProtocolClick() {
    const protocolId = uuidv4();
    const protocol: Protocol = {
      address: "",
      protocolItems: {},
      date: new Date().toISOString(),
      recorderName: "",
      orderNumber: null,
      geolocation: null,
    };
    setProtocols({
      ...protocols,
      [protocolId]: protocol,
    });
    postProtocol(protocolId);
    patchProtocol(protocolId, {
      ...emptyProtocolPatch,
      date: protocol.date,
    });

    (async () => {
      const geolocation = await getCurrentPosition();
      if (geolocation != null) {
        patchProtocol(protocolId, { ...emptyProtocolPatch, geolocation });
      }
    })();

    (async () => {
      const name = await getUserName();
      if (name != null) {
        patchProtocol(protocolId, {
          ...emptyProtocolPatch,
          recorderName: name,
        });
        setProtocols({
          ...protocols,
          [protocolId]: {
            ...protocol,
            recorderName: name,
          },
        });
      }
    })();

    navigate({
      kind: RouteKind.Protocol,
      protocolId,
    });
  }

  return (
    <main
      style={{
        marginLeft: 8,
        marginRight: 8,
        display: "flex",
        flexDirection: "column",
        gap: 8,
        paddingTop: 8,
        paddingBottom: 8,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 16,
        }}
      >
        <QrCodePrintElement />
        {userType === "Admin" && <InviteButton />}
      </div>
      <h2>Protokolle </h2>
      <div style={{ paddingBottom: 64 }}>
        {protocols != null && (
          <table className="table">
            <thead>
              <tr>
                <th>Adresse</th>
                <th>Datum</th>
                {!isMobile && (
                  <>
                    <th>Protokollant</th>
                    <th>Autos</th>
                    <th>Auftragsnummer</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {Object.entries(protocols)
                .map(([protocolId, protocol]): [ProtocolId, Protocol] => {
                  if (protocol == null) {
                    // This shouldn't be possible -- I don't understand why ts-rs generates a type with
                    // nullable values/keys.
                    throw new Error(`Protocol is null ${protocolId}`);
                  }
                  return [protocolId, protocol];
                })
                .sort(([, a], [, b]) => {
                  if (a.date == null && b.date == null) {
                    return 0;
                  }
                  if (a.date == null) {
                    return 1;
                  }
                  if (b.date == null) {
                    return -1;
                  }
                  return a.date > b.date ? -1 : 1;
                })
                .map(([protocolId, protocol]) => {
                  return (
                    <ProtocolSummaryElement
                      key={protocolId}
                      protocolId={protocolId}
                      protocol={protocol}
                    />
                  );
                })}
            </tbody>
          </table>
        )}
      </div>
      <button
        style={{
          ...squareIconButtonStyle,
          zIndex: 1000,
          position: "fixed",
          bottom: 20,
          right: 20,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        className="btn btn-dark"
        type="button"
        onClick={handleAddProtocolClick}
      >
        <i className="bi bi-plus-lg"></i>
      </button>
    </main>
  );
}
