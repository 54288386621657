import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Sign } from "./bindings/Sign";

import noStopping from "./no-stopping.png";
import noStoppingLeft from "./no-stopping-left.png";
import noStoppingRight from "./no-stopping-right.png";
import noStoppingLeftRight from "./no-stopping-left-right.png";
import noParking from "./no-parking.png";
import noParkingLeft from "./no-parking-left.png";
import noParkingRight from "./no-parking-right.png";
import noParkingLeftRight from "./no-parking-left-right.png";

// Define the props for the ImageSelector component
interface SignSelectProps {
  value: Sign;
  onChange: (selectedImage: Sign) => void;
}

function signImageUrl(sign: Sign): string | null {
  switch (sign) {
    case "noStopping":
      return noStopping;
    case "noStoppingLeft":
      return noStoppingLeft;
    case "noStoppingRight":
      return noStoppingRight;
    case "noStoppingLeftRight":
      return noStoppingLeftRight;
    case "noParking":
      return noParking;
    case "noParkingLeft":
      return noParkingLeft;
    case "noParkingRight":
      return noParkingRight;
    case "noParkingLeftRight":
      return noParkingLeftRight;
    case "other":
      return null;
    default: {
      const exhaustive: never = sign;
      throw new Error(`Unhandled sign: ${exhaustive}`);
    }
  }
}

function signName(sign: Sign): string {
  switch (sign) {
    case "noStopping":
      return "Halteverbot";
    case "noStoppingLeft":
      return "Halteverbot links";
    case "noStoppingRight":
      return "Halteverbot rechts";
    case "noStoppingLeftRight":
      return "Halteverbot links und rechts";
    case "noParking":
      return "Parkverbot";
    case "noParkingLeft":
      return "Parkverbot links";
    case "noParkingRight":
      return "Parkverbot rechts";
    case "noParkingLeftRight":
      return "Parkverbot links und rechts";
    case "other":
      return "Sonstiges Schild";
    default: {
      const exhaustive: never = sign;
      throw new Error(`Unhandled sign: ${exhaustive}`);
    }
  }
}

export function SignSelect({ value, onChange }: SignSelectProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  function SignDropDownItem({ sign }: { sign: Sign }): JSX.Element {
    const imageUrl = signImageUrl(sign);
    return (
      <DropdownItem
        key={sign}
        onClick={() => onChange(sign)}
        active={value === sign}
      >
        <div className="d-flex align-items-center">
          {imageUrl != null && (
            <img
              src={imageUrl}
              className="mr-2"
              style={{ width: "50px", height: "50px" }}
            />
          )}
          <span>{signName(sign)}</span>
        </div>
      </DropdownItem>
    );
  }

  const imageUrl = signImageUrl(value);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {imageUrl != null && (
        <img src={imageUrl} style={{ width: "50px", height: "50px" }} />
      )}
      <div>{signName(value)}</div>
      <Dropdown isOpen={isOpen} toggle={toggleDropdown}>
        <DropdownToggle className="btn btn-dark" caret>
          Schild ändern
        </DropdownToggle>
        <DropdownMenu>
          <SignDropDownItem sign={"noStopping"} />
          <SignDropDownItem sign={"noStoppingLeft"} />
          <SignDropDownItem sign={"noStoppingRight"} />
          <SignDropDownItem sign={"noStoppingLeftRight"} />
          <SignDropDownItem sign={"noParking"} />
          <SignDropDownItem sign={"noParkingLeft"} />
          <SignDropDownItem sign={"noParkingRight"} />
          <SignDropDownItem sign={"noParkingLeftRight"} />
          <SignDropDownItem sign={"other"} />
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

export default SignSelect;
