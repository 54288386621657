import { Protocol } from "../bindings/Protocol";
import { Protocols } from "../bindings/Protocols";
import { ProtocolId } from "../bindings/ProtocolId";
import { ProtocolPatch } from "../bindings/ProtocolPatch";
import { fetchGetAdaptive, fetchMutatingAdaptive } from "../fetch-queue";

export async function getProtocol(protocolId: ProtocolId): Promise<Protocol> {
  const response = await fetchGetAdaptive(`/api/protocol/${protocolId}`);

  if (!response.ok) {
    throw new Error(`Failed to fetch protocol: ${protocolId}`);
  }

  return response.json();
}

export const emptyProtocolPatch: ProtocolPatch = {
  address: null,
  date: null,
  recorderName: null,
  orderNumber: null,
  geolocation: null,
  protocolItems: null,
};

export async function patchProtocol(
  protocolId: ProtocolId,
  protocol: ProtocolPatch,
): Promise<void> {
  await fetchMutatingAdaptive(`/api/protocol/${protocolId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(protocol),
  });
}

export async function postProtocol(protocolId: ProtocolId): Promise<void> {
  await fetchMutatingAdaptive(`/api/protocol/${protocolId}`, {
    method: "POST",
  });
}

export async function getProtocols(): Promise<Protocols> {
  const response = await fetchGetAdaptive(`/api/protocol`);

  if (!response.ok) {
    throw new Error("Failed to get protocol");
  }

  return response.json();
}

export function downloadLinkPdf(protocolId: ProtocolId): string {
  return `/api/protocol/${protocolId}/download/pdf`;
}

export function downloadLinkHtml(protocolId: ProtocolId): string {
  return `/api/protocol/${protocolId}/download/html`;
}
